import { VueEditor } from "vue2-editor";

const availableLanguages = [
    { text: "English", value: "en" },
    { text: "Vietnamese", value: "vi" },
]

export default function multiLanguageContentInput(label = "Language Contents", defaultExpandDisabled = false, contentType = VueEditor) {
    return {
        type: "XArrayInput",
        attrs: {
            label,
            xOptions: {
                content: {
                    defaultExpandDisabled,
                    itemLabel(item) {
                        const language = availableLanguages.find(({ value }) => value === item.language)
                        return item.language && language.text || item.language;
                    },
                    itemProperties: {
                        language: {
                            type: "select",
                            attrs: {
                                label: "Language",
                                items: availableLanguages,
                                required: true,
                            },
                        },
                        content: {
                            type: contentType,
                            attrs: {
                                label: "Content",
                                required: true,
                            },
                        },
                    },
                    template: {
                        formOptions: {
                            content: {
                                colLength: 12,
                            },
                        },
                    },
                },
            },
        },
    };
}
